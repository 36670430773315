import React from "react"
import styled from 'styled-components'
import { navigate } from "gatsby"

const ImageRoundButton = ({size, url, children }) => {
  const goTo = (path) => {
    navigate(path);
  }

  return (
    <Btn onClick={()=> goTo(url)} size={size}>{children}</Btn>
  )
} 
export default ImageRoundButton

const Btn = styled.div`
    display: flex;
    padding: 12px;
    width:  ${props => props.size};
    height: ${props => props.size};
    cursor: pointer;
    border-radius: 50%;
    overflow: visible;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(0,0,0,0.10);
      @media (hover: none): {
        background-color: transparent;
      }
    },
`;
