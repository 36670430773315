import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Grid2Auto from '../../components/Layout/Grid2/Grid2Auto'
import GridItem from '../../components/Layout/GridItem/GridItem'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ImageRoundButton from '../../components/Layout/Button/ImageRoundButton'


const articlesPage = ({ location, data }) =>
(
	<Layout>

		<SEO title="Summon Buyer Guides"
			description="Buying a prestige car? Read our Buyer Guides for tips on getting the best price and the right car."
			location={location}
		/>

		<PageHeader
			title="Summon Buyer Guides"
			text="Buying a prestige car? Read our Buyer Guides for tips on getting the best price and the right car. 
			Below are popular cars with Summon customers, buyers and sellers. Also, some of our personal favourites."
		/>
		
		
		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/buying-guides/bmw-x3-buying-guide/">
						Buying a BMW X3? Read more for X3 prices and our advice on buying the best example
						</HeadingLink>
					</Heading2>
					<Paragraph1>
					Read our market analysis for ​​BMW X3 xDrive30d (G01), 2017-2019
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/buying-guides/bmw-x3-buying-guide/">
						<BackgroundImage
							fluid={data.bmwX3.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>

		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/buying-guides/porsche-macan-buying-guide/">
						<BackgroundImage
							fluid={data.porscheMacan.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/buying-guides/porsche-macan-buying-guide/">
						Buying a Porsche Macan? Read more for Macan prices and our advice on buying the best car
						</HeadingLink>
					</Heading2>
					<Paragraph1>
					Read our market analysis for ​​Porsche Macan, Macan S and Macan S Diesel
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>



		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/buying-guides/mercedes-benz-glc-250-buying-guide/">
							Buying a Mercedes-Benz GLC 250? Read our buyers guide for prices, specs and advice on how to buy a good one
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Read our market analysis for Mercedes-Benz GLC 250 & 250d
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/buying-guides/mercedes-benz-glc-250-buying-guide/">
						<BackgroundImage
							fluid={data.mercedesBenzGlc250.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/buying-guides/bmw-m3-competition-buying-guide/">
						<BackgroundImage
							fluid={data.bmwM3Competition.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/buying-guides/bmw-m3-competition-buying-guide/">
							Buying a BMW M3 Competition? Read our buyers guide for prices, specs and advice on how to buy a good one
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Read our market analysis for the BMW M3 Competition (F80, LCI), 2016-2018
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>




		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/buying-guides/porsche-911-carrera-gts-buying-guide/">
							Buying a Porsche 911 Carrera GTS? Carrera GTS prices, specs and how to buy a good one
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Read our market analysis for ​​Porsche 911 Carrera GTS 991.1 and 991.2
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/buying-guides/porsche-911-carrera-gts-buying-guide/">
						<BackgroundImage
							fluid={data.porche911CarreraGts.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/buying-guides/toyota-landcruiser-buying-guide/">
						<BackgroundImage
							fluid={data.toyotaLandcruiser.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/buying-guides/toyota-landcruiser-buying-guide/">
							Buying a Toyota Landcruiser? Landcruiser prices, specs and how to buy a good one
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Read our market analysis for ​​Toyota Land Cruiser 200-series mk2: GXL, VX and Sahara
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>


	</Layout>
)

export default articlesPage

const HeadingLink = styled(Link)`
	text-decoration: none;
`;

export const query = graphql`
	query  {

		bmwX3: file(relativePath: { eq: "buying-guides/article-bmw-x3.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		porscheMacan: file(relativePath: { eq: "buying-guides/porsche-macan-articles-circle.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		mercedesBenzGlc250: file(relativePath: { eq: "buying-guides/glc-articles-circle.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		bmwM3Competition: file(relativePath: { eq: "buying-guides/articles-circle-bmw-m3.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		porche911CarreraGts: file(relativePath: { eq: "buying-guides/articles-circle-911-gts.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		toyotaLandcruiser: file(relativePath: { eq: "buying-guides/articles-circle-toyotalandcruiser.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

	}`;
